import React, { createRef, useRef, useState, useEffect } from "react";
import Avatar from "@atlaskit/avatar";
import Comment, {
    CommentAuthor,
    CommentTime,
} from "@atlaskit/comment";
import styled from "styled-components";
import CommentForm from "../comments/comment-form";
import useDocumentHistory from "../../hooks/use-document-history";
import Spinner from "../ui/spinner";
import Button, { ButtonGroup } from "@atlaskit/button";
import Branch24Icon from "@atlaskit/icon-object/glyph/branch/24";
import DOMPurify from "dompurify";
import Tooltip, { TooltipPrimitive } from "@atlaskit/tooltip";
import { useTranslation } from "react-i18next";

const scrollToRefObject = (ref, offset) => {
    ref.current.scrollIntoView({
        behavior: "smooth",
    });
};

const CommentItem = ({ comment }) => {
    return <>
        <Comment
            avatar={<Avatar size="medium" src={comment.avatar_url} />}
            author={<CommentAuthor>{comment.first_name + " " + comment.last_name}</CommentAuthor>}
            time={<CommentTime>{(new Date(comment.created_at)).toLocaleString()}</CommentTime>}
            content={
                <CommentMessage comment={comment} />
            }
        />
        <br />
    </>;
};

export const CommentMessage = ({ comment }) => {
    const html = DOMPurify.sanitize(comment.message?.replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')).replaceAll(/@\[.*?\]\((\d+)\)/g, function (matches, id) {
        const user = comment?.mentions?.find(i => parseInt(i.id) === parseInt(id));
        if (!user) return "";
        return `<span class="mention">@${user?.first_name ?? "-"} ${user?.last_name ?? "-"}</span>`;
    });


    return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

const HistoryItem = ({ history }) => {
    let message = history.message;
    let data;
    try {
        data = JSON.parse(history?.data) ?? {};
    } catch (e) {
        data = {};
    }

    Object.keys(data)?.map(key => {
        const mapped = (("" + data[key]).startsWith("data:image/png;base64")) ? "<img width='100' height='50' src='" + data[key] + "'></strong>" : "<strong>" + data[key] + "</strong>";
        message = message.replaceAll(`{${key}}`, mapped);
    });

    return <>
        <Comment
            avatar={<HistoryAvatarWrapper>
                <HistoryAvatarIcon>
                    <Branch24Icon />
                </HistoryAvatarIcon>
                {history.first_name && <HistoryAvatarAvatar>
                    <Avatar size="small" src={history.avatar_url} />
                </HistoryAvatarAvatar>}
            </HistoryAvatarWrapper>}
            time={<CommentTime>{(new Date(history.created_at)).toLocaleString()}</CommentTime>}
            content={
                <p dangerouslySetInnerHTML={{ __html: message }} />
            }
        />
        <br />
    </>;
};

export default function DocumentComments({ document, data, commentsRef, isPending, type, setType, reload }) {
    const { t } = useTranslation();

    return <Wrapper>
        <Heading>
            <h4>{t("document_comments_heading")}</h4>
        </Heading>
        <CommentForm documentId={document.id} onAdded={() => {
            if (type === "history") setType("comments");
            reload();
        }} />
        {isPending && <Spinner />}
        <ButtonsWrapper>
            <ButtonGroup>
                <Button onClick={() => setType("comments")} appearance={type == "comments" ? "default" : "subtle"}>
                    {t("document_comments_comments")}
                </Button>
                <Button onClick={() => setType("history")} appearance={type == "history" ? "default" : "subtle"}>
                    {t("document_comments_history")}
                </Button>
                <Button onClick={() => setType("all")} appearance={type == "all" ? "default" : "subtle"}>
                    {t("document_comments_all")}
                </Button>
            </ButtonGroup>
        </ButtonsWrapper>
        {!isPending && data && data.data.length === 0 && <NoItemsWrapper>
            {t("document_comments_empty")}
        </NoItemsWrapper>}
        <CommentsWrapper ref={commentsRef}>
            {data && data.data && data.data.map(item => {
                if (item.type === "history") return <HistoryItem key={item.id} history={item} />;
                return <CommentItem key={item.id} comment={item} />;
            })}
        </CommentsWrapper>
    </Wrapper>;
}

const Wrapper = styled.div`
margin-top: 40px;
`;

const Heading = styled.div`
    display: flex;

    &>h4 {
        flex-grow: 1;
    }
`;

const ButtonsWrapper = styled.div`
    margin-top: 20px;
`;

const CommentsWrapper = styled.div`
    margin-top: 30px;
`;

const NoItemsWrapper = styled.div`
    margin: 20px;
`;

const InlineDialog = styled(TooltipPrimitive)`
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-sizing: content-box; /* do not set this to border-box or it will break the overflow handling */
  color: #333;
  max-height: 300px;
  max-width: 300px;
  padding: 8px 12px;
`;

const HistoryAvatarWrapper = styled.div`
    position: relative;
    width: 36px;
`;
const HistoryAvatarIcon = styled.div`
    position: absolute;
`;
const HistoryAvatarAvatar = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
`;