import React from "react";
import { DocumentPageInner } from "../../pages/document";

export default function Drawer({ module, category, space, id, onClose, onReload = null }) {
    return <div className="drawerWrapper" tabIndex="-1">

        <div className="drawerClose" onClick={() => onClose()}>
            &times;
        </div>

        <div className="">
            <DocumentPageInner slug={space} id={id} drawerLayout documentLink onReload={onReload} showTabs onDrawerClose={() => {
                onClose();
            }} />
        </div>

        <style dangerouslySetInnerHTML={{
            __html: `
            .drawerWrapper {
                background-color: rgb(255, 255, 255);
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                height: 100vh;
                line-height: 24px;
                max-width: 1000px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                overflow-y: auto;
                padding-bottom: 24px;
                padding-left: 24px;
                padding-right: 24px;
                padding-top: 24px;
                position: fixed;
                right: 0px;
                tab-size: 4;
                top: 0px;
                width: 100%;
                z-index: 300;
            }

            .drawerClose {
                border-bottom-color: rgb(229, 231, 235);
                border-bottom-style: solid;
                border-bottom-width: 0px;
                border-left-color: rgb(229, 231, 235);
                border-left-style: solid;
                border-left-width: 0px;
                border-right-color: rgb(229, 231, 235);
                border-right-style: solid;
                border-right-width: 0px;
                border-top-color: rgb(229, 231, 235);
                border-top-style: solid;
                border-top-width: 0px;
                box-sizing: border-box;
                color: rgb(0, 0, 0);
                cursor: pointer;
                display: block;
                font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                font-feature-settings: normal;
                font-variation-settings: normal;
                font-size: 24px;
                height: 56px;
                left: 0px;
                line-height: 24px;
                outline-color: rgb(0, 0, 0);
                outline-style: none;
                outline-width: 0px;
                padding-bottom: 16px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 21px;
                position: absolute;
                tab-size: 4;
                top: 0px;
                width: 41.765625px;
                z-index: 99999999999;
            }
            `
        }}>

        </style>
    </div>;
}