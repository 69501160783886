import React, { useEffect, useState } from "react";
import EditDocumentForm from "./edit-document-form";
import styled from "styled-components";
import CellValue from "../../utils/cell-value";
import Lozenge from "@atlaskit/lozenge";
import toBoolean from "../../utils/to-boolean";
import HipchatChevronDownIcon from "@atlaskit/icon/glyph/hipchat/chevron-down";
import HipchatChevronUpIcon from "@atlaskit/icon/glyph/hipchat/chevron-up";
import Tooltip from "@atlaskit/tooltip";
import QuestionCircleIcon from "@atlaskit/icon/glyph/question-circle";
import InlineEdit from "../forms/inline-edit";
import { useLocation } from "react-router-dom";
import Widgets from "../widgets/widgets";
import Documents from "./documents";
import SectionMessage from "@atlaskit/section-message/section-message";
import SectionMessageAction from "@atlaskit/section-message/section-message-action";
import documentResponse from "../../api/document-response";
import { ErrorMessage } from "@atlaskit/form";
import { useAuth } from "../../providers/auth-provider";
import useAsync from "../../hooks/use-async";
import apiClient from "../../api/api-client";

function useHash() {
    return useLocation().hash?.slice(1);
}

function Document(props) {
    const { currentSection, document, fields, editMode, error, onChange, setDocumentsToLinkAfterSave, setDocumentsToUnlinkAfterSave, setDocumentsToUpdateAfterSave, data = {}, propagateFields, reload, hideSectionTitles = false } = props;
    const [hidden, setHidden] = useState([]);
    const [errors, setErrors] = useState(null);
    const { user } = useAuth();
    const { run: runAllusers, data: allUsers } = useAsync();

    useEffect(() => {
        runAllusers(apiClient("allUsers"));
    }, []);


    useEffect(() => {
        const h = [];
        fields?.filter(f => f?.field?.type === "section")?.forEach(field => {
            if (field?.name != currentSection) {
                h.push(field?.sectionId);
            }
        });
        setHidden(h);
    }, [fields?.length, document?.id, currentSection]);

    const onSectionToggle = sectionId => {
        if (hidden.includes(sectionId)) {
            setHidden(h => h.filter(i => i != sectionId));
        }
        else {
            setHidden(h => [...h, sectionId]);
        }
    };

    return <>
        {fields
            .filter(i => !toBoolean(i.hidden))
            .filter(i => isVisible(i, document, user))
            .filter(f => !f?.group)
            .sort((a, b) => a.position - b.position).map(field => {
                return <DocumentField
                    key={field?.id}
                    {...props}
                    field={field}
                    hidden={hidden}
                    onSectionToggle={onSectionToggle}
                    errors={errors}
                    allUsers={allUsers}
                    user={user}
                    setErrors={setErrors}
                />;
            })}


        {document?.linkable.map(l => {
            if (decodeURI(currentSection) == l?.name) {
                const optionsData = l?.config;
                return <>
                    {optionsData?.widgets && document?.document_type?.space?.category?.module && <Widgets widgets={optionsData?.widgets} module={document?.document_type?.space?.category?.module?.name} documentId={document?.id} />}
                    {optionsData?.message && <SectionMessageWrapper>
                        <SectionMessage
                            title={optionsData?.message?.title}
                            appearance={optionsData?.message?.appearance}
                            actions={optionsData?.message?.actions?.map(a => (
                                <SectionMessageAction key={a.label} href={a?.href}>{a?.label}</SectionMessageAction>
                            ))}
                        >
                            {optionsData?.message?.body}
                        </SectionMessage>
                    </SectionMessageWrapper>}
                    <Documents
                        defaultSortDir={optionsData?.sortDir}
                        document={document}
                        hideCreate={optionsData?.hideCreate === true}
                        disableCreate={optionsData?.disableCreate === true}
                        disableEdit={optionsData?.disableEdit === true}
                        disableDelete={optionsData?.disableDelete === true}
                        useColumnsOrder={optionsData?.useColumnsOrder === true}
                        hideTitle={hideSectionTitles}
                        linkTypeId={optionsData?.link_type_id}
                        documentTypeId={l?.id}
                        document_links={optionsData?.links}
                        editMode={editMode}
                        filtersEnabled
                        onReload={reload}
                        currentSection={currentSection}
                        isDrawer={l?.config?.drawer}
                        noColumnOrder
                        showColumnMenuTool
                        colorField={optionsData?.colorField}
                        colorColumn={optionsData?.colorColumn}
                        bgField={optionsData?.bgField}
                        columnsWidths={optionsData?.columnsWidths ?? {}}
                        columnsGroups={optionsData?.columnsGroups ?? {}}
                        defaultColumnWidth={optionsData?.defaultColumnWidth}
                        slug={l?.space}
                        config={optionsData}
                        columns={optionsData?.columns} />
                </>;
            }
        })}
    </>;
}

export default Document;

const Row = styled.div`
    margin-top: 8px;
`;

const Label = styled.label`
font-size: 1em;
font-style: inherit;
font-weight: 600;
letter-spacing: -0.003em;
margin-top: 16px;
line-height: 24px;
color: #6B778C;
overflow-wrap: break-word;
min-width: 0px;
`;

const Value = styled.div`
    box-sizing: border-box;
    display: block;
    max-width: 100%;
    width: auto;
    margin-bottom: ${props => props.margin}px;
    font-size: 1.0rem;
    padding-top: ${props => props.paddingTop ?? 8}px;
`;

const Section = styled.div`
margin-bottom: 10px;
margin-top: 40px;
border-bottom: 1px solid #eee;
padding-bottom: 10px;
display: flex;
cursor: pointer;
`;

const SectionTitle = styled.h4`
    flex: 1;
    padding-right: 20px;
`;

const SectionIcon = styled.div`
`;

const Wrapper = styled.div`
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
`;

const HelpWrapper = styled.div`
    padding: 0 5px 0;
    cursor: help;
`;

const SectionMessageWrapper = styled.div`
margin-top: 20px;
`;

const Required = styled.span`
font-size: 10px;
color: red;
padding: 0 6px;
`;

export function isVisible(f, document, user) {
    try {
        const roles = user?.roles?.map(r => r?.id) ?? [];
        const documentVisible = (document?.visible_roles ?? {})?.[f?.id];

        if (documentVisible?.length > 0) {
            const contains = roles?.some(id => documentVisible?.includes(id));
            return contains;
        }

        const visible = f?.visible_roles ?? [];

        if (visible?.length > 0) {
            const contains = roles?.some(id => visible?.includes(id));
            return contains;
        }

        const options = JSON.parse(f?.field?.type == "select" || f?.field?.type == "multiselect" ? f?.select_options : f?.options[0]);

        if (options?.visibleWhen) {
            return options?.visibleWhen?.some((condition) => {
                const { field, operator, value } = condition;
                const currentValue = document?.values?.[field] ?? null;


                switch (operator) {
                    case "=":
                        if (currentValue == value) {
                            return true;
                        }
                        break;
                    case "!=":
                        if (currentValue != value) {
                            return true;
                        }
                        break;
                    case "in":
                        if (value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                    case "notIn":
                        if (!value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                }
            });
        }

        if (options?.visibleWhenAll) {
            return options?.visibleWhen?.every((condition) => {
                const { field, operator, value } = condition;
                const currentValue = document?.values?.[field] ?? null;

                switch (operator) {
                    case "=":

                        if (currentValue == value) {
                            return true;
                        }
                        break;
                    case "!=":
                        if (currentValue != value) {
                            return true;
                        }
                        break;
                    case "in":
                        if (value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                    case "notIn":
                        if (!value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                }
            });
        }
    } catch (e) {
        return true;
    }
    return true;
}

export function isEditable(f, document, user) {
    try {
        const roles = user?.roles?.map(r => r?.id) ?? [];
        const editable = f?.editable_roles ?? [];

        if (editable?.length > 0) {
            const contains = roles?.some(id => editable?.includes(id));
            return contains;
        }

        const options = JSON.parse(f?.field?.type == "select" || f?.field?.type == "multiselect" ? f?.select_options : f?.options[0]);

        if (options?.editableWhen) {
            return options?.editableWhen?.some((condition) => {
                const { field, operator, value } = condition;
                const currentValue = document?.values?.[field] ?? null;


                switch (operator) {
                    case "=":
                        if (currentValue == value) {
                            return true;
                        }
                        break;
                    case "!=":
                        if (currentValue != value) {
                            return true;
                        }
                        break;
                    case "in":
                        if (value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                    case "notIn":
                        if (!value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                }
            });
        }

        if (options?.editableWhenAll) {
            return options?.editableWhen?.every((condition) => {
                const { field, operator, value } = condition;
                const currentValue = document?.values?.[field] ?? null;

                switch (operator) {
                    case "=":

                        if (currentValue == value) {
                            return true;
                        }
                        break;
                    case "!=":
                        if (currentValue != value) {
                            return true;
                        }
                        break;
                    case "in":
                        if (value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                    case "notIn":
                        if (!value?.includes(currentValue)) {
                            return true;
                        }
                        break;
                }
            });
        }

        return true;
    } catch (e) {
        return true;
    }
}

function DocumentField(props) {
    const {
        field, currentSection, document, fields, editMode, error,
        onChange, setDocumentsToLinkAfterSave, setDocumentsToUnlinkAfterSave,
        setDocumentsToUpdateAfterSave, data = {}, propagateFields, reload, hideSectionTitles = false,
        hidden,
        onSectionToggle,
        errors,
        allUsers,
        setErrors,
        user,
    } = props;

    const sectionHidden = hidden?.includes(field?.sectionId);

    if (sectionHidden || (!!currentSection && !field?.sectionId)) return null;

    if (field.field.type === "section") {
        if (hideSectionTitles) return null;

        return <Section>
            <SectionTitle>{field.label}</SectionTitle>
        </Section>;
    }

    const value = document.values[field.id];
    const inputProps = {
        key: field.id,
        name: field.name,
        isRequired: (!!field.required || !!field?.must_have) && !toBoolean(field.read_only),
        defaultValue: field?.field?.type === "checkbox" ? toBoolean(document.values[field.id]) : document.values[field.id],
        isInvalid: error?.errors[field.name],
        table_column: field?.table_column || [],
        columns_values: document?.columns_values,
        isDisabled: toBoolean(field?.read_only ?? 0),
        rawValues: document?.rawValues || [],
        autoFocus: false
    };

    const fieldError = errors?.[field?.name]?.[0] ?? null;

    const help = field?.help?.length > 0 &&
        <HelpWrapper>
            <Tooltip content={field?.help}>
                <QuestionCircleIcon size="small" />
            </Tooltip>
        </HelpWrapper>;

    if (field?.field?.type == "group") {
        return <FieldsGroup {...props} field={field} fields={fields} />;
    }

    return <Row key={field.id}>
        {field?.field?.type === "checkbox"
            || field?.field?.type === "table"
            || field?.field?.type === "message"
            || field?.field?.type === "widgets"
            || field?.field?.type === "heading"
            || field?.field?.type === "divider" ? null : <Label>
            <Wrapper>
                {field.label}{(!!field?.required || !!field?.must_have) && <Required>*</Required>} {help}
            </Wrapper>

            {fieldError && <ErrorMessage>{fieldError}</ErrorMessage>}

        </Label>}
        <Value
            margin={field?.field?.type === "message" ? 0 : 30}
            paddingTop={field?.field?.type === "message" ? 0 : 8}>
            <InlineEdit
                value={value}
                allUsers={allUsers}
                reload={reload}
                onSaved={() => reload && reload()}
                document={document}
                isRequired={!!field?.required || !!field?.must_have}
                propagateFields={propagateFields}
                field={field}
                fields={fields}
                setErrors={setErrors}
                inputProps={inputProps}
                root={document}
                editable={editMode && isEditable(field, document, user)} />
        </Value>
    </Row>;
}

export function FieldsGroup(props) {
    const { field, fields } = props;

    const groupFields = fields?.filter(f => f?.group === field?.name) ?? [];
    const sorted = groupFields?.sort((a, b) => parseInt(a?.group_lp) - parseInt(b?.group_lp));
    const count = groupFields?.length ?? 0;

    if (count <= 0) return null;

    return <div style={{
        display: "flex",
        gap: "10px",
        width: "100%",
    }}>
        {sorted?.map(gf => {
            return <div key={`gf-${gf?.name}`} style={{
                flex: gf?.group_flex ?? 1,
            }}>
                <DocumentField {...props} field={gf} isGroup />
            </div>;
        })}
    </div>;
}